import './Header.css';

function Header() {
    return (
            <div className="header">
                <h1>Welcome to Claudia's site</h1>
                <p>Grade five</p>
            </div>
    );
}

export default Header;
