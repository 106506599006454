import './NaughtyOrNice.css';
import { useState } from "react";

function NaughtyOrNice() {

    const [ name, setName ] = useState( "" );

    function handleCheck( event ) {
        event.preventDefault()
        event.stopPropagation()

        const formData = new FormData( event.target )
        let kidsName = formData.get( "name" ).toString()
        setName( kidsName );
    }

    function niceOrNaughty( name ) {

        if ( (name[ 0 ] === "a" ) || (name[ 0 ] === "A" )) {
            return "naughty";
        } else {
            return "nice";
        }
    }

    return (
        <div className="NaughtyOrNice">
            <h2 className="nn-header">Are you Naughty or Nice</h2>
            <p>Type your name</p>

            <form onSubmit={ handleCheck }>

                <label htmlFor="name-typed">Name: </label> &nbsp;
                <input type="text" id="name-typed" name="name"/>&nbsp;

                <input type="submit" value="Check"/>
            </form>

            { ( name === "" ) ? null :
                < h4> Hi { name } you are in { niceOrNaughty( name ) } list of Santa!</h4>
            }
        </div>
    );
}

export default NaughtyOrNice;
