import './App.css';
import Header from "../Sections/Header/Header";
import NaughtyOrNice from "../Sections/NaughtyOrNice/NaughtyOrNice";

function App() {
    return (
        <div className="App">
            <Header/>
            <NaughtyOrNice/>
        </div>
    );
}

export default App;
